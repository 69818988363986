import { useState } from 'react';
import './BountyCreate.scss';
import { Alert, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { postBounty } from '../../api';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

export default function BountyCreate() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [hours, setHours] = useState(24);
  const [mask, setMask] = useState(1);

  async function postCicked() {
    try {
      if (!title) {
        setError("Title cannot be blank");
      } else {
        const seconds = hours * 3600;
        if (seconds <= 3600) {
          setError("Invalid value for hours remaining");
        }
        setLoading(true);

        const postResult = await postBounty(title, seconds);
        if (postResult.status !== 200) {
          setError("Error posting bounty (" + postResult.status + ")");
        } else {
          navigate('/bounty/' + postResult.data.bountyId);
        }
      }
    }
    catch (error) {
      setError("Error " + error);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className='bounty-create'>
      <div className='fields'>
        <TextField
          fullWidth
          label='Heading'
          variant='outlined'
          helperText="Give a detailed description to make your fantasy come to life."
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
        />
        <RadioGroup row value={hours} onChange={(event) => {
          setHours(Number(event.target.value))
        }}>
          <FormControlLabel value={8} control={<Radio />} label="8 hrs" />
          <FormControlLabel value={24} control={<Radio />} label="24 hrs" />
          <FormControlLabel value={168} control={<Radio />} label="7 days" />
          <FormControlLabel value={720} control={<Radio />} label="30 days" />
        </RadioGroup>
        <div>
        <RadioGroup row value={mask} onChange={(event) => {
          setMask(Number(event.target.value))
        }}>
          <FormControlLabel value={1} control={<Radio />} label="Pictures" />
          <FormControlLabel value={2} control={<Radio />} label="Shorts" />
          <FormControlLabel value={4} control={<Radio />} label="Videos" />
        </RadioGroup>
        </div>
        {error && (
          <Alert severity="error">{error}</Alert>
        )}
        <LoadingButton 
          variant="contained" 
          loading={loading} 
          onClick={postCicked}>
          <span>Post Bounty</span>
        </LoadingButton>
      </div>
    </div>
  );
};
