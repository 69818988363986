import './ClaimVoteBar.scss';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from 'react';
import { getVote, postVote } from '../../api';
import { Claim } from '../../types';

interface ClaimVoteBarProps {
  claim: Claim;
  onVote: (vote: number) => void;
}

export default function ClaimVoteBar({ claim, onVote }: ClaimVoteBarProps) {
  const [vote, setVote] = useState(-1);

  useEffect(() => {
    if (claim?.claimId) {
      getVote(claim.claimId).then(result => {
        setVote(result.status !== 200 ? -1 : result.data);
      });
    }
  }, [claim]);

  async function handleVote(stars: number) {
    try {
      setVote(-1);
      const result = await postVote(claim.claimId, stars);
      if (result.status !== 200) {
        console.error('Post Vote Error: ' + result.status);
      } else {
        setVote(stars);
        onVote(stars);
      }
    }
    catch (error) {
      console.error("Vote Clicked Error: ", error);
    }
  }

  return (
    <div className='claim-vote-bar'>
      {
        [1, 2, 3, 4, 5].map(stars => (
          (vote === 0) ? 
          <FontAwesomeIcon key={stars} className='vote active' icon={faStar} onClick={()=>handleVote(stars)} />
          :
          <FontAwesomeIcon key={stars} className={`vote ${vote >= stars ? 'selected' : 'disabled'}`} icon={faStar} />       
        ))
      }
    </div>
  )
}