
export interface IAddress {
  street: string;
  city: string;
  state: string;
  zip: string;
}

export interface IBankInfo {
  name: string;
  accountType: string;
  routingNumber: string;
  accountNumber: string;
}

export interface ICreditCard {
  number: string;
  month: string;
  year: string;
  cvv: string;
  name: string;
  zip: string;
}

export type ImageUpload = {
  key: string,
  width: number,
  height: number
}

export type Session = {
  token: string;
}

export type UserFull = {
  user: User;
  hunter: Hunter;
}

export type User = {
  userId: number;
  role: number;
  status: number;
  handle: string;
  firstName: string;
  lastName: string;
  email: string;
  votes: number;
  silver: number;
  gold: number;
  creditCardLastFour: string;
  active: Date;
  membership: number;
  idFront: string;
  idSelfie: string;
  taxAddress: IAddress;
  ssnLastFour: string;
  bankAccountLastFour: string;
  bankInfo: IBankInfo;
  created: Date;
  unresolved: boolean;
}

export type Hunter = {
  hunterId: number;
  handle: string;
  stagename: string;
  bio: string;
  followers: number;
  picture: string;
  banner: string;
  created: Date;
  unresolved: boolean;
}

export type Post = {
  postId: string;
  hunterId: string; 
  text: string;
  file: string;
  likes: number;
  created: Date;
  unresolved: boolean;
}

export type Bounty = {
  bountyId: number;
  userId: number;  
  title: string;
  seconds: number;
  roundCount: number;
  created: Date;
  rounds: Round[];
  unresolved: boolean;
}

export type Round = {
  bountyId: number;
  roundId: number;
  silver: number;
  gold: number;
  endDate: Date;
  created: Date;
  claims: Claim[];
  unresolved: boolean;
}


export type Claim = {
  claimId: number;
  bountyId: number;
  roundId: number;
  hunterId: number;
  media: string;
  votes: number;
  created: Date;
  unresolved: boolean;
}

export type ClaimExtended = Claim & {
  bounty: Bounty;
  round: Round;
  hunter: Hunter;
};

export enum RoundStatus {
  Unknown,
  Pending,
  Inacitve,
  Active,
}