import { useParams } from 'react-router-dom';
import './BountyPage.scss';
import { getBounty } from '../../api';
import { Bounty, Claim } from '../../types';
import { useEffect, useState } from 'react';
import BountyBar from './BountyBar';
import ImageUploader from '../ImageUploader';
import ClaimCreateDialog from './ClaimCreateDialog';
import RoundSummary from './RoundSummary';
import { round } from 'lodash';

export default function BountyPage() {
  const { id } = useParams();
  const bountyId = parseInt(id || "");
  const [bounty, setBounty] = useState({} as Bounty);
  const [file, setFile] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (bountyId) {
      getBounty(bountyId).then(result => {
        setBounty(result.status === 200 ? result.data : {} as Bounty);
      });
    }
  }, [id]);

  async function onHandleUpload(status: number, file: string) {
    if (status !== 200) {
      console.error("onHandleUpload", status);
    } else {
      console.log("onHandleUpload: ", file);
      setFile(file);
      setDialogOpen(true);
    }
  }

  return (
    <div className='content-page'>
      <BountyBar bounty={bounty} />
      <ImageUploader onUpload={onHandleUpload}>Add Claim</ImageUploader>
      {
        bounty.rounds?.map(round =>
          <RoundSummary key={round.roundId} round={round} />
        )
      }
      <ClaimCreateDialog open={dialogOpen} setOpen={setDialogOpen} bounty={bounty} file={file} />
    </div>
  );
};
