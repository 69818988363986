import './ClaimBar.scss';
import { Claim } from '../../types';
import { formatPostDate, formatRank } from '../../globals';
import { useEffect, useState } from 'react';
import { getClaimRank } from '../../api';

interface ClaimBarProp {
  claim: Claim;
}

export default function ClaimBar({ claim }: ClaimBarProp) {
  const [rank, setRank] = useState(0);

  useEffect(() => {
    if (claim.bountyId > 0) {
      getClaimRank(claim.claimId).then(result => {
        if (result.status === 200) {
          setRank(result.data);
        }
      });
    }
  }, [claim]);

  return (
    <div className='claim-bar'>
      <span>{ formatRank(rank) }</span>
      <span>{ claim.votes } votes</span>
      <span className='date'>{formatPostDate(claim.created)}</span>
    </div>
  );
};
