import { LoadingButton } from '@mui/lab';
import './Plan.scss';
import { Alert } from '@mui/material';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';
import { updateMembership } from '../../api';

const plans = [
  {
    name: "Standard",
    price: "$6.99",
    qualityShort: "720p",
    qualityLong: "720p (HD)",
    silverTokens: 50,
    goldTokens: 200,
  },
  {
    name: "Premium",
    price: "$15.49",
    qualityShort: "1080p",
    qualityLong: "1080p (Full HD)",
    silverTokens: 100,
    goldTokens: 600,
  },
  {
    name: "Deluxe",
    price: "$22.99",
    qualityShort: "4K + HDR",
    qualityLong: "4K (Ultra HD) + HDR",
    silverTokens: 200,
    goldTokens: 1000,
  }
];

export default function Plan() {
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();
  const [selected, setSelected] = useState(plans.length - 1);
  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState('');

  async function applyClicked() {
    try {
      setPageError('');
      setLoading(true);
  
      const result = await updateMembership(selected);
      if (result.status !== 200) {
        setPageError("We encountered an issue while processing your information (Error: " + result.status + "). Please verify your details are correct and try again, or contact our support team for assistance.");
      } else {
        setUser(prev => ({ ...prev, membership: selected }));
        navigate('/signup/handle/user');
      }
    }
    catch (error) {
      setPageError("A system error occurred. We apologize for the inconvenience. Please try again later, or if the issue persists, contact our support team for further assistance. Error Details: " + error);
    }
    finally {
      setLoading(false);
    }
  }

  if (user.role !== 1 || new Date(user.active) > new Date()) {
    return <Navigate to="/join" replace />;
  }

  return (
    <div className='form-page'>
      <h3>Step 2 of 4</h3>
      <h1>Choose the plan that's right for you</h1>
      <div className='plan-picker'>
        {
          plans.map((plan, index) => (
            <div className={'plan' + (index === selected ? ' selected': '')} onClick={() => setSelected(index)}>
              <b>{plan.name}</b>
              <p>{plan.qualityShort}</p>
            </div>
          ))
        }
      </div>
      <div className='plan-info'>
        <p>Monthly price</p>
        <b>{ plans[selected].price }</b>
      </div>
      <div className='plan-info'>
        <p>Video and picture quality</p>
        <b>{ plans[selected].qualityLong }</b>
      </div>
      <div className='plan-info'>
        <p>Monthly silver tokens</p>
        <b>{ plans[selected].silverTokens }</b>
      </div>
      <div className='plan-info'>
        <p>Monthly gold tokens</p>
        <b>{ plans[selected].goldTokens }</b>
      </div>
      {pageError && <Alert severity="error">{pageError}</Alert> }
      <LoadingButton 
        variant="contained" 
        loading={loading} 
        onClick={applyClicked}>
        <span>{"Next"}</span>
      </LoadingButton>
    </div>
  );
};
