
import { useEffect, useState } from 'react';
import { appConfig } from '../appConfig';
import './ImageViewer.scss';
import { parseImageSize } from '../globals';

interface ImageViewerProps {
  file: string;
}

export default function ImageViewer({ file }: ImageViewerProps) {
  const [src, setSrc] = useState('/blank.png');
  const [size, setSize] = useState({ width: 500, height: 500 });

  useEffect(()=> {
    setSrc('/blank.png');
    const parsedSize = parseImageSize(file);
    setSize(parsedSize.width > 0 ? parsedSize : { width: 500, height: 500});
    if (file) {
      const final = new Image();
      final.src = appConfig.mediaUrl + file + '-f.webp';
      if (final.complete) {
        setSrc(final.src);
      } else {
        const thumb = new Image();
        thumb.src = appConfig.mediaUrl + file + '-t.webp';
        if (thumb.complete) {
          setSrc(thumb.src);
          final.onload = () => {
            setSrc(final.src);
          }
        } else {
          thumb.onload = () => {
            setSrc(thumb.src);
            if (final.complete) {
              setSrc(final.src);
            } else {
              final.onload = () => {
                setSrc(final.src);
              }
            }
          }
        }
      }
    }
  }, [file]);

  return (
    <img className='image-viewer' src={src} alt='Bounty Image' width={size.width} height={size.height}></img>
  );
}