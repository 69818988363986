import { useParams } from 'react-router-dom';
import './ClaimPage.scss';
import { useEffect, useState } from 'react';
import { ClaimExtended, RoundStatus } from '../../types';
import { getClaim } from '../../api';
import BountyBar from './BountyBar';
import { useAppContext } from '../../contexts/AppContext';
import ClaimBar from './ClaimBar';
import BountyFeed from './BountyFeed';
import HunterBar from './HunterBar';
import MediaViewer from '../MediaViewer';
import ScrollToTop from '../ScrollToTop';
import { roundStatus } from '../../globals';
import { useUserContext } from '../../contexts/UserContext';
import ClaimVoteBar from './ClaimVoteBar';

export default function ClaimPage() {
  const { id } = useParams();
  const { snackError } = useAppContext();
  const { loadUser } = useUserContext();
  const [claimId, setClaimId] = useState(0);
  const [claim, setClaim] = useState({ unresolved: true } as ClaimExtended);

  useEffect(() => {
    setClaimId(parseInt(id || "0"));
  }, [id]);

  useEffect(() => {
    setClaim({ unresolved: true } as ClaimExtended);
    if (claimId) {
      getClaim(claimId).then(result => {
        if (result.status !== 200) {
          snackError("Error loading claim: " + result.status);
          console.error('getClaim: %d', result.status);
        } else {
          console.log("CLAIM", result.data);
          setClaim(result.data);
        }
      });
    }
  }, [claimId]);

  function handleVote() {
    loadUser();
    getClaim(claimId).then(result => {
      if (result.status !== 200) {
        console.error('getClaim: %d', result.status);
      } else {
        console.log("CLAIM", result.data);
        setClaim(result.data);
      }
    });
  }
  
  return (
    <div className='claim-page'>
      <ScrollToTop />
      <div className='claim-box'>
        <BountyBar bounty={claim.bounty} />
        <MediaViewer file={claim.media} />
        <ClaimBar claim={claim} />
        {
          roundStatus(claim.round) === RoundStatus.Active && 
          <ClaimVoteBar claim={claim} onVote={handleVote} />
        }
      </div>
      <HunterBar hunter={claim.hunter} />
      { claim.bountyId && <BountyFeed search={claim.bounty.title} claimId={claim.claimId} excludeId={claim.bountyId} /> }
    </div>
  );
}