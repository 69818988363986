import './BountyGrid.scss';
import { Bounty } from '../../types';
import ClaimThumb from './ClaimThumb';

interface BountyGridProps {
  bounty: Bounty;
  roundIndex: number;
}

export default function BountyGrid({ bounty, roundIndex }: BountyGridProps) {
  return (
    <div className="bounty-grid" key={bounty.bountyId}>
    {
      bounty.rounds[roundIndex].claims.map(claim => <ClaimThumb key={claim.claimId} claim={claim} />)
    }
    </div>
  );
}
