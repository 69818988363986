import './PostCreate.scss';
import { useEffect, useState } from 'react';
import { Alert, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import MediaUploader from './MediaUploader';
import { getBounty } from '../api';
import { Bounty } from '../types';
import PageLoading from './PageLoading';

export default function PostCreate() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [pageError, setPageError] = useState('');
  const [bounty, setBounty] = useState({} as Bounty);
  const [resolved, setResolved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const [file, setFile] = useState('');

  useEffect(() => {
    setResolved(false);
    getBounty(Number(id)).then(result => {
      setResolved(true);
      if (result.status !== 200) {
        setPageError('Error loading bounty: ' + result.status);
      } else {
        setBounty(bounty);
      }
    });
  }, [id]);

  async function postClicked() {
    try {
      setPageError('');
      //const info = getFileUploadInfo(file);
      /*const result = await postClaim(text, info.fileType, { } as IFile);
      if (result.status !== 200) {
        setPageError('Error posting claim: ' + result.status);
      } else {
        navigate('/claim/' + result.data.claimId);
      }*/
    }
    catch (error) {
      console.error("postClicked", error);
      setPageError("Post error: " + error);
    }
    finally {
      setLoading(false);
    }
  }

  async function onHandleUpload(status: number, file: string) {
    if (status !== 200) {
      console.error("onHandleUpload", status);
    } else {
      setFile(file);
    }
  }

  if (!resolved) return <PageLoading />

  return (
    <div className='form-page'>
      <h1>Create Post</h1>
      <h2>Select a Photo, Video, or GIF to Share</h2>
      {file && <img className='post-picture' src={'https://videos.bounty.fan/' + file } alt='post' />}
      <MediaUploader onUpload={onHandleUpload}>Upload Media</MediaUploader>
      <h2>Detail Your Post</h2>
      <TextField
        fullWidth
        label='Caption'
        helperText='Bring your post to life with words. A little context goes a long way in helping others appreciate your content more fully.'
        variant='outlined'
        value={text}
        onChange={(event) => {
          setText(event.target.value.substring(0,200));
        }}
      />
      {pageError && <Alert severity="error">{pageError}</Alert> }
      <LoadingButton 
        variant="contained" 
        loading={loading} 
        onClick={postClicked}>
        <span>Post</span>
      </LoadingButton>
      <Button onClick={() => { navigate('/bounty/' + id)}}>Cancel</Button>
    </div>
  );
};
