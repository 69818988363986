import React, { createContext, useContext, useEffect, useState } from 'react';
import { Hunter, User, UserFull } from '../types';
import { getUser, logout } from '../api';

export interface UserContextType {
  loadUser: () => void;
  setUserFull: (full: UserFull) => void;
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  hunter: Hunter;
  setHunter: React.Dispatch<React.SetStateAction<Hunter>>;
  logoutUser: () => Promise<void>;
}

export function useUserContext(): UserContextType {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
}

const UserContext = createContext<UserContextType | null>(null);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loadCount, setLoadCount] = useState(0);
  const [user, setUser] = useState({ unresolved: true } as User);
  const [hunter, setHunter] = useState({ } as Hunter);

  function setUserFull(full: UserFull) {
    setUser(full.user || {});
    setHunter(full.hunter || {});
  };

  useEffect(() => {
    const token = localStorage.getItem('ss');
    console.log(`TOKEN: ${token}`);
    if (!token) {
      setUserFull({} as UserFull);
    } else {
      getUser().then(result => {
        if (result.status !== 200) {
          logoutUser();
        } else {
          setUserFull(result.data);
        }
      }).catch(error => {
        console.error("getUser", error);
        logoutUser();
      });
    }
  }, [loadCount]);

  function loadUser() {
    setLoadCount((value) => value + 1);
  }

  async function logoutUser() {
    const token = localStorage.getItem("ss");
    console.log(`LOGOUT: ${token}`);
    if (token) {
      await logout(token);
      localStorage.removeItem("ss");
    }
    setUserFull({} as UserFull);
  }
  
  return (
    <UserContext.Provider value={
      { 
        loadUser,
        setUserFull,
        user,
        setUser,
        hunter,
        setHunter,
        logoutUser 
      }
    }>
      {children}
    </UserContext.Provider>
  );
}

