import * as React from 'react';
import { LoadingButton } from '@mui/lab';
import { uploadImage } from '../api';

interface MediaUploaderProps {
  onUpload: (status: number, file: string) => void;
  children: React.ReactNode; 
}

export default function ImageUploader({ onUpload, children }: MediaUploaderProps) {
  const [loading, setLoading] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      event.persist();
      const files = event.target.files;
      setLoading(true);
      if (!files) {
        console.log('File List Null');
      } else {
        const file = files.item(0);
        if (!file) {
          console.log('File Null');
        } else {
          const result = await uploadImage(file);
          onUpload(result.status, result.status === 200 ? result.data.key : "");
        }
      }
    } catch (ex) {
      console.log("Error", ex);
    }
    finally {
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    inputRef.current?.click();
  };


  return (
    <LoadingButton
      variant="contained" 
      loading={loading}
      onClick={handleButtonClick}
    >
      { loading ? 'Uploading' : children }
      <input
        accept="image/*"
        ref={inputRef}
        multiple={false}
        type="file"
        hidden
        onChange={handleFileInputChange}
      />
    </LoadingButton>
  );
}
