const devConfig = {
  sfw: true,
  apiUrl: "http://localhost:5052",
  mediaUrl: "https://bountyuploads.blob.core.windows.net/images/",
}

const prodConfig = {
  sfw: false,
  apiUrl: "https://bountywebapi-fsasddamb8agadak.westus2-01.azurewebsites.net",
  mediaUrl: "https://bountyuploads.blob.core.windows.net/images/",
}

function loadAppConfig() {
  let url = window.location.origin;
  if (url.includes("bounty.fan")) {
    return prodConfig;
  }
  return devConfig;
}

export const appConfig = loadAppConfig();