import './BountyBar.scss';
import { Bounty } from '../../types';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

interface BountyBarProps {
  bounty: Bounty;
}

export default function BountyBar({ bounty }: BountyBarProps) {
  const navigate = useNavigate();
  
  function bountyClicked() {
    navigate(`/bounty/${bounty.bountyId}`);
  }

  if (!bounty) {
    return <div />;
  }

  return (
    <div className='bounty-bar' onClick={bountyClicked}>
      <div className='title'>{bounty.title}</div>
    </div>
  );
};
