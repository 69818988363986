import ImageViewer from "./ImageViewer";

interface MediaViewerProps {
  file: string;
}

export default function MediaViewer({ file }: MediaViewerProps) {
  switch(file) {
    default:
      return <ImageViewer file={file} />
  }
}