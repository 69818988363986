import './RoundSummary.scss';
import { Round } from '../../types';
import ClaimThumb from './ClaimThumb';

interface RoundSummaryProps {
  round: Round;
}

export default function RoundSummary({ round }: RoundSummaryProps) {

  return (
    <div className="round-summary">
      {
        round.claims?.map(claim => 
          <ClaimThumb key={claim.claimId} claim={claim} />
        )
      }
    </div>
  );
}
